require('normalize.css');
require('./style.sass');
const axios = require('axios').default;

import "@fontsource/roboto";
import "bootstrap-icons/font/bootstrap-icons.css";
import moment from 'moment';

import {exists, contains, getSelectedText} from './utils.js';

window.$  = function(x){ return document.querySelector(x) }
window.$$ = function(x){ return document.querySelectorAll(x) }

window.REQUESTING = false
window.REQUESTS = []
window.MOBILE = window.matchMedia('(max-device-width: 812px)').matches

tags = [];

function filter_pills(inp){
    var rows = $$('#history .row')
    if (inp.length < 2){
        rows.forEach((row)=>{ row.style.display = 'flex' })
        return
    }

    inp = inp.toLowerCase()
    var regex = new RegExp(inp, 'g')

    // search through pills - popular
    var pills = $$('#popular .pill')
    pills.forEach((pill, i) => {
        var txt = pill.textContent

        if (!exists(txt.match(regex)))
            pill.style.display = 'none'
        else
            pill.style.display = 'flex'
    });

    // search through pills - history
    var pills = $$('#history .pill')

    rows.forEach((row)=>{ row.style.display = 'none' })

    pills.forEach((pill, i) => {
        var txt = pill.textContent
        var wrapper = pill.closest('.table-wrapper')
        var rows = wrapper.querySelectorAll('.row')

        // hide entire rows if match isnt found
        if (exists(txt.match(regex))){
            pill.closest('.row').style.display = 'flex'
        }
    })

    var tables = $$('#history .table-wrapper')
    tables.forEach((table)=>{
        var rows = [...table.querySelectorAll('.row')]
        var nb_rows = rows.length
        var nb_hidden = rows.filter(d => d.style.display == 'none').length
        table.style.display = nb_rows == nb_hidden ? 'none' : 'block'
    })


}

function new_pill(el, text){
    if (text.length == 0)
        return
    el.textContent = ''
    var pills = el.closest('.entry').querySelector('.pills')
    pills.style.display = 'flex'
    pill = document.querySelector('.pill.template').cloneNode(true)
    pill.classList.remove('template')
    pill.querySelector('span').textContent = text
    pills.append(pill)

    bind_events()
    reset_search()
}

function reset_search(){

    var pills = $$('#popular .pill').forEach((pill, i) => {
        pill.style.display = 'flex'
    });
}

function bind_events(){

    $$('.input').forEach((item, i) => {
        item.oninput = function(e){
            filter_pills(e.target.textContent)
        }

        item.onkeyup = function(e){
            var el = e.target
            var text = el.textContent

            if (e.key == 'Backspace') {
                if (text.length > 0)
                    return

                reset_search()
            }

        }

        item.onkeydown = function(e){
            var el = e.target
            var text = el.textContent
            if (e.key == 'Enter'){
                e.preventDefault()
                if (text.length > 0)
                    new_pill(el, text)
                else
                    upload()
            }
            else if (e.key == 'Backspace') {
                if (text.length > 0)
                    return

                var pills = el.closest('.entry').querySelector('.pills')
                if (pills.children.length == 0)
                    return

                pills.children[pills.children.length-1].remove()
                if (pills.children.length == 0){
                    pills.style.display = 'none'
                }
            }
        }
    });


    $$('.close').forEach((item, i) => {
        let handler = function(e){
            var el = e.target
            el.parentElement.remove()
        }

        item.onclick = handler
        item.ontouchend = handler
    });


    $$('.delete').forEach((item, i) => {

        let handler = function(e){
            let el = e.target
            let id = el.getAttribute('data-id')

            let req_id = request_started();
            axios.post('backend/gateway.php', {
                action: 'delete',
                id: id
            })
            .then(function (response) {
                if (response.data.result == 1){
                    el.parentElement.remove()
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(()=>{
                request_ended(req_id)

                fetch_history()
                bind_events()
            })

            e.stopPropagation();
        }

        item.onclick = handler
        item.ontouchend = handler
    });

    $$('.create i').forEach((item, i) => {
        item.onclick = function(e){
            var el = e.target.previousElementSibling
            var text = el.textContent
            new_pill(el, text)
        }
    });

    $$('.pill-add').forEach((item, i) => {
        item.onclick = function(e){
            add_pill_to_entry(item.getAttribute('data-tag'))
        }
    });

}

function clear_all_pills(){
    $$('#entries .pill').forEach(function(e){
        if (!e.classList.contains('create'))
            e.remove()
    })

    $$('.input').forEach((item, i) => {
        item.textContent = ''
    });

    reset_search()
}

function fetch_history(){
    console.log('fetch_history')

    var req_id = request_started();
    axios.post('backend/gateway.php', {
        action: 'history',
    })
    .then(function (response) {
        var data = response.data

        $('#history').innerHTML = '<h2>History</h2>'+data;

        bind_events()
    })
    .catch(function (error) {
        console.log(error);
    })
    .finally(()=>{
        request_ended(req_id)
    })

}

function upload(){
    var datetime = $('#datetime').value.replace('T',' ')
    var entries = []
    var e = 1
    $$('.entry').forEach((item,i)=>{
        contents = []
        item.querySelectorAll('.pill').forEach((pill,j)=>{
            if (pill.classList.contains('create'))
                return

            var content = pill.querySelector('span').textContent
            content = content.toLowerCase()
            contents.push(content)
        })
        if (contents.length > 0)
            entries.push(contents)
        e += 1
    })

    if (entries.length == 0)
        return

    var req_id = request_started();
    axios.post('backend/gateway.php', {
        action: 'insert',
        data: {
            datetime: datetime,
            entries: entries,
        }
    })
    .then(function (response) {
        fetch_tags()
        fetch_history()
        clear_all_pills()
    })
    .catch(function (error) {
        console.log(error);
    })
    .finally(()=>{
        request_ended(req_id)
    })
}

function request_started(){
    var loading = $('#loading-layer')
    loading.style.display = 'flex'

    var icon = $('#loading')
    icon.setAttribute('data-deg', 0)

    // loading animation: rotation
    var id = window.setInterval(()=>{
        var deg = icon.getAttribute('data-deg')
        deg = (parseFloat(deg) + 10) % 360
        icon.style.transform = 'rotate('+deg+'deg)'
        icon.setAttribute('data-deg', deg)
    }, 50)

    window.REQUESTING = true
    window.REQUESTS.push(id)
    return id
}

function request_ended(id){
    window.REQUESTS = window.REQUESTS.reduce((arr,v)=>{
        if (v != id)
            arr.push(v)
        return arr
    }, [])
    window.REQUESTING = window.REQUESTS.length > 0

    if (window.REQUESTING)
        return

    var loading = $('#loading-layer')
    loading.style.display = 'none'

    var icon = $('#loading')
    icon.setAttribute('data-deg', 0)
    icon.style.transform = 'rotate(0deg)'
    window.clearInterval(id)
}

function fetch_tags(){
    console.log('fetch_tags')

    var req_id = request_started();
    axios.post('backend/gateway.php', {
        action: 'popular',
    })
    .then(function (response) {
        var data = response.data

        data = data.sort((a, b) => b.freq - a.freq)

        ids = []
        tags = []
        freqs = []
        var items = response.data.map((d)=>{
            ids.push(d['id'])
            tags.push(d['item'])
            freqs.push(d['freq'])
        })
        $('#popular .pills').innerHTML = ''

        tags.forEach((tag, i) => {
            var freq = freqs[i]
            var id = ids[i]
            var el = document.createElement('div')
            var el_freq = document.createElement('span')
            el_freq.textContent = ' '+freq+'x'
            el_freq.classList.add('pill-freq')
            el_freq.style.display = 'none'
            var txt = document.createTextNode(tag)
            el.classList.add('pill')
            el.classList.add('pill-add')
            el.setAttribute('data-tag', tag)
            el.setAttribute('data-id', id)
            el.appendChild(txt)
            el.appendChild(el_freq)
            $('#popular .pills').append(el)
        });

        bind_events()

    })
    .catch(function (error) {
        console.log(error);
    })
    .finally(()=>{
        request_ended(req_id)
    })
}

function add_pill_to_entry(tag){
    var el = $('.entry[active=true] span.input')
    new_pill(el, tag)
}


window.onload = function(){

    $('#clear').onclick = function(e){
        clear_all_pills()
    }

    $('#jump-top').onclick = function(e){
        var el = $('#content')
        el.scrollTop = 0
    }

    $('#jump-bottom').onclick = function(e){
        var el = $('#content')
        el.scrollTop = el.scrollHeight
    }

    $('#show-datetime i').onclick = function(e){
        var el = e.target
        el.classList.toggle('bi-calendar2-week')
        el.classList.toggle('bi-calendar2-week-fill')

        var el = $('#datetime-picker')
        if (el.style.display == 'none' || el.style.display == '')
            el.style.display = 'block'
        else
            el.style.display = 'none'

        set_datetime_picker()
    }

    $('#show-freq .bi-toggle-off').onclick = function(e){
        var el = e.target
        el.classList.toggle('bi-toggle-off')
        el.classList.toggle('bi-toggle-on')
        $$('.pill-freq').forEach((el, i) => {
            if (el.style.display != 'none')
                el.style.display = 'none'
            else
                el.style.display = 'block'
        });
    }

    $('#send').onclick = upload

    document.addEventListener('keydown', function(e){
        if (e.key == 'Escape'){
            clear_all_pills()
        }
    })

    let touchstartX = 0
    let touchendX = 0

    function checkDirection() {
      if (touchendX < touchstartX - 100){
          upload()
      }
    }

    document.addEventListener('touchstart', e => {
      touchstartX = e.changedTouches[0].screenX
    })

    document.addEventListener('touchend', e => {
      touchendX = e.changedTouches[0].screenX
      checkDirection()
    })

    function set_datetime_picker(){
        var datetime = moment().format().slice(0,16)
        $('#datetime').setAttribute('value', datetime)
    }

    fetch_history()
    fetch_tags()
    set_datetime_picker()
    bind_events()
}
