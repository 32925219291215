export function exists(obj){
    return !(typeof obj == 'undefined' || obj == null || obj.length == 0)
}

export function contains(arr, el){
    return arr.indexOf(el) > -1
}

export function getSelectedText(){
    if (window.getSelection) {
        text = window.getSelection()

    } else if (document.getSelection) {
        text = document.getSelection()

    } else if (document.selection) {
        text = document.selection.createRange().text
    }

    return text.toString()
}
